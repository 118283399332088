// @codekit-prepend 'assets/js/jquery.fancybox.js'
// @codekit-prepend 'assets/js/jquery.flexslider.js'

jQuery( document ).ready( function( $ ) {

	// Theme Main JQuery functions
	$( ".handle" ).on( "click", function() {
		$( ".overlay-menu" ).toggleClass( "show" );
		$( this ).toggleClass( "clicked" );
		$( "body" ).toggleClass( "fixed" );
		$( "html" ).toggleClass( "fixed" );

	} );

	// Slide in search
	$( ".search-slide" ).click( function() {
		$( ".form" ).fadeToggle( "fast", function() {
		} );
	} );

	$( ".search-slide" ).click( function( e ) {
		$( ".form" ).show();
		e.stopPropagation();
	} );

	$( ".form" ).click( function( e ) {
		e.stopPropagation();
	} );

	$( document ).click( function() {
		$( ".form" ).fadeOut( "fast" );
	} );

	$( ".tour" ).click( function() {
		$( this ).find( "iframe" ).addClass( "clicked" );
	} ).mouseleave( function() {
		$( this ).find( "iframe" ).removeClass( "clicked" );
	} );

	// Adding :hover & :focus states on Mobile
	window.onload = function() {
		if ( /iP(hone|ad)/.test( window.navigator.userAgent ) ) {
			document.body.addEventListener( "touchstart", function() {}, false );
		}
	};

	// Initialize the Lightbox and add rel="gallery" to all gallery images when the gallery is set up using [gallery link="file"] so that a Lightbox Gallery exists
	$( "[data-fancybox]" ).fancybox( {
		youtube: {
			showinfo: 0,
		},
	} );

	$( ".gallery a[href$='.jpg'], .gallery a[href$='.png'], .gallery a[href$='.jpeg'], .gallery a[href$='.gif']" )
		.attr( "data-fancybox", "gallery" )
		.fancybox();
	$( "a[href$=\"jpg\"], a[href$=\"png\"], a[href$=\"jpeg\"]" ).fancybox( {
		fullScreen: true,
		thumbs: false,
		fullScreen: false,
		thumbs: false,
		loop: false,
		slideShow: false,
	} );
} );

